<template>
  <ul role="list" class="divide-y rounded-md border">
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Normal</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled || !checkExported({ type: 'period' })"
          :disabled="disabled || !checkExported({ type: 'period' })"
          size="sm"
          class="w-full"
        >
          <span>{{ disabled ? 'Belum diproses' : 'Tidak ada ekspor' }}</span>
        </base-button>
        <base-button
          v-else
          :loading="loading.downloadPeriod"
          size="sm"
          class="w-full"
          @click="onDownloadPeriod"
        >
          Download
        </base-button>
      </div>
    </li>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="min-w-0 flex-1 gap-2">
          <span class="block truncate font-medium">Periode Tambah Point</span>
          <span class="block text-xs text-gray-500">
            {{
              mitraPeriod.data.attributes.bonus_start_date | formatDate('LL LT')
            }}
            -
            {{
              mitraPeriod.data.attributes.bonus_end_date | formatDate('LL LT')
            }}
          </span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <div v-if="bonus_order_days.length" class="flex items-center">
          <base-chevron
            :collapse="collapsedBonus"
            v-on:click="collapsedBonus = !collapsedBonus"
          >
            <template #left>
              <span class="flex-shrink-0 text-gray-400"
                >{{
                  bonus_order_days.length + (disabledBonusStart ? 1 : 0)
                }}
                File</span
              >
            </template>
          </base-chevron>
        </div>
        <base-button v-else disabled size="sm" class="w-full">
          Belum diproses
        </base-button>
      </div>
    </li>
    <template v-if="!collapsedBonus">
      <!-- Bonus Awal Periode Normal - Awal Periode Bonus -->
      <li
        class="flex items-center justify-between bg-gray-100 p-4 text-sm leading-6"
        v-if="disabledBonusStart"
      >
        <div class="flex w-0 flex-1 items-center">
          <div class="flex min-w-0 flex-1 items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-4 w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
            <span class="truncate">
              {{ bonus_start.start_date | formatDate('LL') }} -
              {{ bonus_start.end_date | formatDate('LL') }}
            </span>
          </div>
        </div>
        <div class="flex-shrink-0">
          <base-button
            :loading="loading.downloadBonusStart"
            :disabled="
              disabled || !checkExported({ type: 'bonus_start' })
            "
            size="sm"
            class="w-full"
            @click="onDownloadBonusStart"
          >
            {{
              disabled
                ? 'Belum Dimulai '
                : checkExported({ type: 'bonus_start' })
                ? 'Download'
                : 'Tidak ada ekspor'
            }}
          </base-button>
        </div>
      </li>
      <li
        v-for="(bonus_day, index) in bonus_order_days"
        :key="bonus_day.date"
        class="flex items-center justify-between bg-gray-100 p-4 text-sm leading-6"
      >
        <div class="flex w-0 flex-1 items-center">
          <div class="flex min-w-0 flex-1 items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-4 w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
            <span class="truncate">{{
              bonus_day.date | formatDate('LL')
            }}</span>
          </div>
        </div>
        <div class="flex-shrink-0">
          <base-button
            :disabled="
              !checkExported({
                type: 'bonus_day',
                date: bonus_day.date,
              })
            "
            :loading="bonus_day.loading"
            size="sm"
            class="w-full"
            @click="onDownloadBonusDate(index, bonus_day.date)"
          >
            {{
              checkExported({
                type: 'bonus_day',
                date: bonus_day.date,
              })
                ? 'Download'
                : 'Tidak ada ekspor'
            }}
          </base-button>
        </div>
      </li>
    </template>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Gabungan</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled"
          :loading="loading.downloadMixed"
          :disabled="disabled"
          size="sm"
          class="w-full"
        >
          <span>Belum diproses</span>
        </base-button>
        <base-button
          v-else
          :loading="loading.downloadMixed"
          :disabled="disabled || !checkExported({ type: 'mixed' })"
          size="sm"
          class="w-full"
          @click="onDownloadMixed"
        >
          {{
            checkExported({ type: 'mixed' })
              ? 'Download'
              : 'Tidak ada ekspor'
          }}
        </base-button>
      </div>
    </li>
  </ul>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseChevron from '@/components/base/BaseChevron.vue';
import { requestMixin } from '@/mixins/request/request';
import { downloadFile } from '@/services/utils.service';

export default {
  name: 'MitraPeriodeOfficeDownloadList',
  components: { BaseButton, BaseChevron },
  mixins: [requestMixin],
  data: function () {
    return {
      collapsedBonus: true,
      bonus_start: {
        start_date: null,
        end_date: null,
      },
      bonus_order_days: [],
      loading: {
        downloadPeriod: false,
        downloadMixed: false,
        downloadBonusStart: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      mitraPeriod: 'mitra_periods/getMitraPeriod',
      currentPeriod: 'mitra_periods/getCurrentPeriod',
    }),
    disabled: function () {
      return dayjs(this.currentPeriod.data.attributes.end_date).isBefore(
        dayjs(this.mitraPeriod.data.attributes.end_date).endOf('day')
      );
    },
    disabledBonusStart: function () {
      return dayjs().isAfter(dayjs(this.bonus_start.end_date).endOf('day'));
    },
  },
  methods: {
    ...mapActions({
      exportDbf: 'offices/exportDbf',
    }),
    setup: function () {
      this.bonus_start.start_date = dayjs(
        this.mitraPeriod.data.attributes.end_date
      ).format('YYYY-MM-DD');
      this.bonus_start.end_date =
        this.mitraPeriod.data.attributes.bonus_order_days[0];
      this.bonus_order_days =
        this.mitraPeriod.data.attributes.bonus_order_days.map((day) => ({
          date: day,
          loading: false,
        }));
    },
    checkExported({ type, date }) {
      switch (type) {
        case 'period':
          return this.mitraPeriod.data.attributes.files.period.office;

        case 'mixed':
          return this.mitraPeriod.data.attributes.files.mixed.office;

        case 'bonus_start':
          return (
            this.mitraPeriod.data.attributes.files.bonus_start.office
          );

        case 'bonus_day':
          return this.mitraPeriod.data.attributes.files[date].office;

        default:
          break;
      }
    },
    async onDownloadPeriod() {
      this.loading.downloadPeriod = true

      await this.onDownload('period')

      this.loading.downloadPeriod = false
    },
    async onDownloadBonusStart() {
      this.loading.downloadBonusStart = true

      await this.onDownload('bonus-start')

      this.loading.downloadBonusStart = false
    },
    async onDownloadBonusDate(index, date) {
      this.bonus_order_days[index].loading = true

      await this.onDownload('bonus-date', date)

      this.bonus_order_days[index].loading = false
    },
    async onDownloadMixed() {
      this.loading.downloadMixed = true

      await this.onDownload('mixed')
      
      this.loading.downloadMixed = false
    },
    async onDownload(type, date) {
      const [data,,res] = await this.request(`/api/v1/mitra-periods/${this.mitraPeriod.data.id}/-actions/download`, {
        params: {
          type: 'office',
          range: type,
          bonus_date: date
        },
        responseType: 'blob'
      })
      const [,filename] = res.headers['content-disposition'].split('filename=')

      downloadFile(data, filename)
    }
  },
  mounted: function () {
    this.setup();
  },
};
</script>

<template>
  <ul role="list" class="divide-y rounded-md border">
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Normal</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled"
          :disabled="disabled"
          size="sm"
          class="w-full"
        >
          <span>Belum diproses</span>
        </base-button>
        <div class="flex gap-x-2" v-else>
          <base-button
            :loading="loading.downloadPeriod"
            :disabled="
              disabled || !checkExported({ type: 'period' })
            "
            size="sm"
            @click="onDownloadPeriod"
          >
            {{
              checkExported({ type: 'period' })
                ? 'Download General'
                : 'Tidak ada ekspor'
            }}
          </base-button>
          <base-button
            :loading="loading.downloadDetailPeriod"
            :disabled="
              disabled || !checkExported({ type: 'period_detail' })
            "
            size="sm"
            @click="onDownloadDetailPeriod"
          >
            {{
              checkExported({ type: 'period_detail' })
                ? 'Download Detail'
                : 'Tidak ada ekspor'
            }}
          </base-button>
        </div>
      </div>
    </li>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Normal Nuera</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled"
          :disabled="disabled"
          size="sm"
          class="w-full"
        >
          <span>Belum diproses</span>
        </base-button>
        <div class="flex gap-x-2" v-else>
          <base-button
            :disabled="
              disabled || !checkExported({ type: 'nuera' })
            "
            :loading="loading.downloadNueraPeriod"
            @click="onDownloadNueraPeriod"
            size="sm"
          >
            {{
              checkExported({ type: 'nuera' })
                ? 'Download General'
                : 'Tidak ada ekspor'
            }}
          </base-button>
          <base-button
            :disabled="
              disabled || !checkExported({ type: 'nuera_detail' })
            "
            :loading="loading.downloadNueraDetailPeriod"
            @click="onDownloadNueraDetailPeriod"
            size="sm"
          >
            {{
              checkExported({ type: 'nuera_detail' })
                ? 'Download Detail'
                : 'Tidak ada ekspor'
            }}
          </base-button>
        </div>
      </div>
    </li>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Tambah Point</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <div v-if="bonus_order_days.length" class="flex items-center">
          <base-chevron
            :collapse="collapsedBonus"
            v-on:click="collapsedBonus = !collapsedBonus"
          >
            <template #left>
              <span class="flex-shrink-0 text-gray-400"
                >{{ bonus_order_days.length }} File</span
              >
            </template>
          </base-chevron>
        </div>
        <base-button v-else disabled size="sm" class="w-full">
          Belum diproses
        </base-button>
      </div>
    </li>
    <template v-if="!collapsedBonus">
      <li
        v-for="(bonus_day, index) in bonus_order_days"
        :key="bonus_day.date"
        class="flex items-center justify-between bg-gray-100 p-4 text-sm leading-6"
      >
        <div class="flex w-0 flex-1 items-center">
          <div class="flex min-w-0 flex-1 items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-4 w-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
            <span class="truncate">{{
              bonus_day.date | formatDate('LL')
            }}</span>
          </div>
        </div>
        <div class="flex-shrink-0">
          <div class="flex gap-x-2">
            <base-button
              :loading="bonus_day.loading"
              :disabled="
                !checkExported({
                  type: 'bonus_day',
                  date: bonus_day.date,
                })
              "
              @click="onDownloadBonusDate(index, bonus_day.date)"
              size="sm"
            >
              {{
                checkExported({
                  type: 'bonus_day',
                  date: bonus_day.date,
                })
                  ? 'Download General'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
            <base-button
              :loading="bonus_day.loadingDetail"
              :disabled="
                !checkExported({
                  type: 'bonus_day_detail',
                  date: bonus_day.date,
                })
              "
              @click="onDownloadBonusDateDetail(index, bonus_day.date)"
              size="sm"
            >
              {{
                checkExported({
                  type: 'bonus_day_detail',
                  date: bonus_day.date,
                })
                  ? 'Download Detail'
                  : 'Tidak ada ekspor'
              }}
            </base-button>
          </div>
        </div>
      </li>
    </template>
    <li class="flex items-center justify-between p-4 text-sm leading-6">
      <div class="flex w-0 flex-1 items-center">
        <div class="flex min-w-0 flex-1 gap-2">
          <span class="truncate font-medium">Periode Gabungan</span>
        </div>
      </div>
      <div class="flex-shrink-0">
        <base-button
          v-if="disabled"
          :loading="loading.downloadMixed"
          :disabled="disabled"
          size="sm"
          class="w-full"
        >
          <span>Belum diproses</span>
        </base-button>
        <div class="flex gap-x-2" v-else>
          <base-button
            :loading="loading.downloadMixed"
            :disabled="disabled || !checkExported({ type: 'mixed' })"
            size="sm"
            @click="onDownloadMixed"
          >
            {{
              checkExported({ type: 'mixed' })
                ? 'Download General'
                : 'Tidak ada ekspor'
            }}
          </base-button>
          <base-button
            :loading="loading.downloadDetailMixed"
            :disabled="
              disabled || !checkExported({ type: 'mixed_detail' })
            "
            size="sm"
            @click="onDownloadDetailMixed"
          >
            {{
              checkExported({ type: 'mixed_detail' })
                ? 'Download Detail'
                : 'Tidak ada ekspor'
            }}
          </base-button>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import dayjs from 'dayjs';
import { downloadFile } from '@/services/utils.service.js';
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseChevron from '@/components/base/BaseChevron.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  name: 'MitraPeriodOrderDownloadList',
  components: { BaseButton, BaseChevron },
  mixins: [requestMixin],
  data: function () {
    return {
      collapsedBonus: true,
      bonus_order_days: [],
      loading: {
        downloadPeriod: false,
        downloadDetailPeriod: false,
        downloadMixed: false,
        downloadDetailMixed: false,
        downloadNueraPeriod: false,
        downloadNueraDetailPeriod: false
      },
    };
  },
  computed: {
    ...mapGetters({
      mitraPeriod: 'mitra_periods/getMitraPeriod',
      currentPeriod: 'mitra_periods/getCurrentPeriod',
    }),
    disabled: function () {
      return dayjs(this.currentPeriod.data.attributes.end_date).isBefore(
        dayjs(this.mitraPeriod.data.attributes.end_date).endOf('day')
      );
    },
  },
  methods: {
    ...mapActions({
      exportDbf: 'orders/exportDbf',
    }),
    setup: function () {
      this.bonus_order_days =
        this.mitraPeriod.data.attributes.bonus_order_days.map((day) => ({
          date: day,
          loading: false,
          loadingDetail: false
        }));
    },
    checkExported({ type, date }) {
      switch (type) {
        case 'period':
          return (
            this.mitraPeriod.data.attributes.files.period.cashbill
          );

        case 'period_detail':
          return (
            this.mitraPeriod.data.attributes.files.period['cashbill-detail']
          );

        case 'nuera':
          return (
            this.mitraPeriod.data.attributes.files.period['cashbill-nuera']
          );

        case 'nuera_detail':
          return (
            this.mitraPeriod.data.attributes.files.period['cashbill-nuera-detail']
          );

        case 'mixed':
          return this.mitraPeriod.data.attributes.files.mixed.cashbill;

        case 'mixed_detail':
          return (
            this.mitraPeriod.data.attributes.files.mixed['cashbill-detail']
          );

        case 'bonus_day':
          return this.mitraPeriod.data.attributes.files[date].cashbill;

        case 'bonus_day_detail':
          return (
            this.mitraPeriod.data.attributes.files[date]['cashbill-detail']
          );

        default:
          break;
      }
    },
    async onDownloadPeriod() {
      this.loading.downloadPeriod = true

      await this.onDownload('cashbill', 'period')

      this.loading.downloadPeriod = false
    },
    async onDownloadDetailPeriod() {
      this.loading.downloadDetailPeriod = true

      await this.onDownload('cashbill-detail', 'period')

      this.loading.downloadDetailPeriod = false
    },
    async onDownloadNueraPeriod() {
      this.loading.downloadNueraPeriod = true

      await this.onDownload('cashbill-nuera', 'period')

      this.loading.downloadNueraPeriod = false
    },
    async onDownloadNueraDetailPeriod() {
      this.loading.downloadNueraDetailPeriod = true

      await this.onDownload('cashbill-nuera-detail', 'period')

      this.loading.downloadNueraDetailPeriod = false
    },
    async onDownloadMixed() {
      this.loading.downloadMixed = true

      await this.onDownload('cashbill', 'mixed')

      this.loading.downloadMixed = false
    },
    async onDownloadDetailMixed() {
      this.loading.downloadDetailMixed = true

      await this.onDownload('cashbill-detail', 'mixed')

      this.loading.downloadDetailMixed = false
    },
    async onDownloadBonusDate(index, date) {
      this.bonus_order_days[index].loading = true

      await this.onDownload('cashbill', 'bonus-date', date)

      this.bonus_order_days[index].loading = false
    },
    async onDownloadBonusDateDetail(index, date) {
      this.bonus_order_days[index].loadingDetail = true

      await this.onDownload('cashbill-detail', 'bonus-date', date)

      this.bonus_order_days[index].loadingDetail = false
    },
    async onDownload(type, range, date) {
      const [data,,res] = await this.request(`/api/v1/mitra-periods/${this.mitraPeriod.data.id}/-actions/download`, {
        params: {
          type: type,
          range: range,
          bonus_date: date
        },
        responseType: 'blob'
      })
      const [,filename] = res.headers['content-disposition'].split('filename=')

      downloadFile(data, filename)
    }
  },
  created: function () {
    this.setup();
  },
};
</script>
